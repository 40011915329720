header{
    height:100vh;
    padding-top:7rem;
    overflow:hidden;
}

.header-container{
    text-align: center;
    height: 100%;
    position:relative;
}

.header-container h1:hover{
    color: var(--color-primary);
}

/* =============== CTA ================ */

.cta{
    margin-top: 2.5rem;
    display: flex;
    gap:1.2rem;
    justify-content: center;
}

/* =============== HEADER SOCIALS ================ */
.header_socials{
    display: flex;
    flex-direction: column;
    align-items:center;
    gap:0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header_socials::after{
    content:'';
    width:1px;
    height:2rem;
    background-color:var(--color-primary);
}

/* =============== ME ================ */
.me{
    background: linear-gradient(var(--color-primary-variant), transparent);
    width: 18rem;
    height: 20rem;
    position: absolute;
    left: calc(50% - 9rem);
    border-radius: 12rem 12rem 0 0;
    padding: 4rem 1.2rem 1.2rem 1.2rem;
    margin-top: 2rem;
}

/* =============== SCROLL DOWN ================ */
.scroll_down{
    position: absolute;
    right: -2.5rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.7rem;
}

/****************** Media Queries (for medium devices) **********************/
@media  screen and (max-width:1024px) {
    .header{
        height: 65vh;
    }
}

/****************** Media Queries (for smaller devices) **********************/
@media  screen and (max-width:600px) {
    .header{
        height: 100vh;
    }
    .header_socials, .scroll_down{
        display: none;
    }
}