.contact-container{
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact-options{
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.contact_option{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact_option:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact_option-icon{
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact_option a{
    margin-top: 0.8rem;
    display: inline-block;
    font-size: 0.8rem;
}


/* =================== FORM ================= */
form{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

input, textarea{
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.7rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white)
}


@media screen and (max-width:1024px){
    .contact-container{
        grid-template-columns: 35% 60%;
        gap: 2.5rem;
    }
}

@media screen and (max-width:600px){
    .container.contact-container{
        width: var(--container-width-ms);
        grid-template-columns: 1fr;
    }
}